<template>
  <div>
    <div class="content_area" v-loading="loading">
      <div class="title">
        <div class="titleText">整箱运价详情</div>
        <div>
          <el-button
            type="success"
            plain
            size="small"
            @click="showDialog"
            v-if="USER_INFO"
            :loading="loading"
            >一键复制</el-button
          >
          <el-button
            class="booking"
            type="success"
            size="small"
            @click="mackInquiry"
            :loading="loading"
            v-if="!USER_INFO || USER_INFO.user_type == 1"
            >我要询价</el-button
          >
          <!-- <el-button
            class="booking"
            type="success"
            size="small"
            @click="handleBookingSpace"
            :loading="loading"
            >快速订舱</el-button
          > -->
        </div>
      </div>
      <div class="mainbox">
        <shipMsg :header="header"></shipMsg>
        <div class="cargoInfo">
          <div class="seaMoney">
            <p class="seaTitle">费用明细</p>
            <p class="itemTips" v-if="!header.is_open && user_type == 1">
              由于近期船公司价格波动加大，请联系销售或客服单独了解航线海运费。
            </p>
            <div class="searchBox">
              请选择箱型：
              <el-checkbox-group
                class="myRedCheckBox"
                @change="change"
                text-color="#000"
                :min="1"
                v-model="checkedArr"
              >
                <el-checkbox
                  v-if="!item.reject"
                  v-for="(item, index) in options"
                  :label="item.name"
                  :key="index"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
              <span style="color: #ff7727; padding-left: 50px"
                >至少需选择一种箱型，将以选择的箱型进行询价和报价</span
              >
            </div>
            <div class="unno" style="margin-top: 20px" v-if="$route.query.unno">
              <div>
                UNNO
                <el-input
                  v-model="unno"
                  style="width: 150px"
                  size="small"
                ></el-input>
                <el-button
                  type="success"
                  class="booking"
                  size="small"
                  style="margin-left: 20px"
                  @click="recalculate"
                  >重新计算</el-button
                >
                <span style="color: #ff7727; padding-left: 50px"
                  >更改UNNO后需重新计算海运费</span
                >
              </div>
              <div class="unnoMsgBox">
                <div class="psn">PSN：{{ unnoMsg.pscn }}</div>
                <div class="class">Class：{{ unnoMsg.classify }}</div>
                <div>PG：{{ unnoMsg.packing_group }}</div>
              </div>
            </div>
          </div>

          <div class="moneyList">
            <div class="seaItem">
              <div class="itemTitleBox">
                <div>
                  <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/miniProgram/ship2.png" style="width:30px;height:30px;margin-right:10px" alt="">
                  <span class="itemTitle">海运费：</span>
                  <span class="itemMOney"
                    >{{ header.currency_code }}

                    <span
                      :class="!header.is_open && user_type == 1 ? 'mohu' : 'price'"
                      >{{ seaTotal }}</span
                    ></span
                  >
                  <span style="padding-left:20px;color:#ff7727" v-if="header.is_open && user_type == 1">可通过询价了解优惠价格</span>
                </div>
              </div>
              <div class="itemContent">
                <div class="tableLine tableTitle">
                  <div class="table1"></div>
                  <div class="table2">币种</div>
                  <div class="table3">计量单位</div>
                  <div class="table4">单价</div>
                </div>
                <div
                  class="tableLine"
                  v-for="(item, index) in options"
                  :key="index"
                  v-if="item.checked && !item.reject"
                >
                  <div class="table1">海运费</div>
                  <div class="table2">{{ header.currency_code }}</div>
                  <div class="table3">{{ item.name }}</div>
                  <div
                    class="table4"
                    :class="!header.is_open && user_type == 1 ? 'mohu' : 'price'"
                  >
                    {{ item.price }}
                  </div>
                </div>
              </div>
            </div>
            <costComp
              :costList='costList'
              @open_close="open_close"
            ></costComp>



            <div class="page_tips">
              <i class="el-icon-warning"></i>
              <span>订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。</span>
            </div>
          </div>
        </div>
      </div>

      
      <el-dialog
        title="选择需要复制的内容"
        :visible.sync="dialogVisible"
        width="400px"
        :before-close="handleClose"
      >
        <div>

          <div class="chooseItem">
              <div class="itemtitle">基本信息</div>
              <div class="handleBox">
                <el-switch class="switch" v-model="check_base" active-color="#91be42" inactive-color="#ccc" ></el-switch>
              </div>
          </div>

          <div
            class="chooseItem"
            v-for="(item, index) in costList"
            :key="index"
          >
            <div class="itemtitle">{{ item.group_name }}</div>
            <div class="handleBox">
              <el-switch
                class="switch"
                v-model="item.canCopy"
                active-color="#91be42"
                inactive-color="#ccc"
              >
              </el-switch>
            </div>
          </div>

          <div class="chooseItem">
              <div class="itemtitle">订舱提示</div>
              <div class="handleBox">
                <el-switch class="switch" v-model="check_tips" active-color="#91be42" inactive-color="#ccc" ></el-switch>
              </div>
          </div>

        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" class="booking" size="small" @click="copy"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import baseInfo from "~ymp/components/common/baseInfo";
import costComp from "~bac/components/ymp/costComp";
import shipMsg from "~bac/components/ymp/shipMsg2side";
export default {
  name: "freightSearchDetailsFcl",
  components:{baseInfo,costComp,shipMsg},

  data() {
    return {
      check_base:true,
      check_tips:true,
      first_load:true,
      unnoOptions: [],
      options: [
        {
          checked: false,
          name: "20GP",
          reject: 0,
        },
        {
          checked: false,
          name: "40GP",
          reject: 0,
        },
        {
          checked: false,
          name: "40HQ",
          reject: 0,
        },
      ],
      checkedArr: [],
      header: {},
      loading: false,
      dialogVisible: false,
      costList: [],
      value: false,
      volume: 0,
      weight: 1000,
      number: 1,
      seaTotal: 0,
      unno: "",
      user_type: 1,
      unnoMsg: {},
    };
  },
  mounted() {
    this.user_type = this.USER_INFO ? this.USER_INFO.user_type : 1;
    if (this.$route.query.unno) {
      this.unno = this.$route.query.unno;
    }
    this.$log({
      type: 1,
      topic: "ymp.inquiry",
      id: "ymp.inquiry.fcl.detail",
      content: JSON.stringify({
        id: this.$route.query.id || '',
      }),
    });
    this.getHeader(true);
  },
  computed: {},
  methods: {
    // 子组件  -打开关闭
    open_close(e){
      this.costList[e.key].open=!this.costList[e.key].open
      this.costList=JSON.parse(JSON.stringify(this.costList))
    },
    mackInquiry() {
      this.$log({
        type: 1,
        topic: "ymp.inquiry",
        id: "ymp.inquiry.fcl.detail.inquire",
        content: JSON.stringify({
          id: this.$route.query.id || '',
        }),
      });
      if (!this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.inquire();
          },
        });
      } else {
        this.inquire();
      }
    },
    async inquire() {
      let params = {
        user_id: this.USER_INFO.id,
        inquiry_type: 1,
        freight_id: this.$route.query.id,
        is_dangerous: this.unno ? 1 : 0,
        classify:this.header.classify,
        un_no: this.unno,
        container: JSON.stringify([
          {
            name:this.options[0].name,
            number:this.options[0].checked?1:0,
            code:this.header.currency_code,
            price:this.options[0].price,
            sell:this.options[0].sell,
            reject:this.header.reject_20gp
          },
           {
            name:this.options[1].name,
            number:this.options[1].checked?1:0,
            code:this.header.currency_code,
            price:this.options[1].price,
            sell:this.options[1].sell,
            reject:this.header.reject_40gp
          },
           {
            name:this.options[2].name,
            number:this.options[2].checked?1:0,
            code:this.header.currency_code,
            price:this.options[2].price,
            sell:this.options[2].sell,
            reject:this.header.reject_45gp
          },
          //  {
          //   name:this.options[3].name,
          //   number:this.options[3].checked?1:0,
          //   code:this.header.currency_code,
          //   price:this.options[3].price,
          //   sell:this.options[3].sell,
          // }
        ]),
        extend: JSON.stringify(this.costList),
      };
      let data = await this.$store.dispatch("API_freight/mackInquire", params);
      if (data.success) {
        this.$confirm("24小时内销售将与您联系，请保持电话畅通！", "您的询价已生成！", {
          confirmButtonText: "查看我的询价",
          cancelButtonText: "留在当前页面",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/console/myInquire/list",
              query: {},
            });
          })
          .catch(() => {});
      }
    },
    openList(item) {
      if (!this.IS_LOGIN) {
        return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            item.open = true;
          },
        });
      } else {
        item.open = true;
      }
    },
    change(e) {
      this.options.forEach((res) => {
        if (JSON.stringify(e).indexOf(res.name) > -1) {
          res.checked = true;
        } else {
          res.checked = false;
        }
      });
      this.componentSea();
      this.getHeader();
    },
    showDialog() {
      this.$log({
        type: 1,
        topic: "ymp.inquiry",
        id: "ymp.inquiry.fcl.detail.copy",
        content: JSON.stringify({
          id: this.$route.query.id || '',
        }),
      });
      if (this.USER_INFO.company_bind_status != 0) {
        this.dialogVisible = true;
      } else {
        this.$router.push({
          path: "/console/company/basic",
          query: {},
        });
      }
    },
    copy() {
      var port =
        `${this.header.from_port || this.header.from_port_name_zh || "-"} - ${
          this.header.dest_port || this.header.dest_port_name_zh || "-"
        }` + "\n";

      if( this.check_base ){
        port = port + (this.header.transshipment_port ? "转 " : "直达 ");
        if (this.header.transshipment_port) {
          port =
            port +
            `${this.header.trans_port || this.header.trans_port_name_zh || "-"} `;
        }
        port = port + "\n";

        this.options.forEach((res) => {
          if (res.checked) {
            port =
              port +
              this.header.currency_code +
              " " +
              (this.user_type==1 && !this.header.is_open?'单询':res.price) +
              "/" +
              res.name +
              ";";
          }
        });
        port = port + "\n";
        port =
          port +
          `船公司：${
            this.header.shipping_company_code
          }， 船期：${this.header.week.join(",")}，航程：${
            this.header.days ? this.header.days : 0
          }，挂靠码头：${this.header.affiliated_terminal}` +
          "\n";
        port =
          port +
          `有效期：${this.$moment(this.header.validity_start * 1000).format(
            "MM/DD"
          )} - ${this.$moment(this.header.validity_end * 1000).format(
            "MM/DD"
          )}` +
          "\n";
        port = port + `注：${this.header.remarks}`+"\n" ;
      }  
      let star = "";
      if (this.costList.length) {
        this.costList.forEach((res, index) => {
          if (res.data_list.length && res.canCopy) {
            star = star + "=========================";
            star = star + "\n";
            star = star + "【" + res.group_name + "】" + "\n";
            res.data_list.forEach((ee) => {
              if (ee.unit == 2) {
                star =
                  star +
                  `-- ${ee.name_zh || ee.name_en || "-"}：${Number(
                    ee.GP20
                  ).toFixed(2)} ${ee.code}/20GP,${Number(ee.GP40).toFixed(2)} ${
                    ee.code
                  }/40GP,${Number(ee.HC40).toFixed(2)} ${ee.code}/40HQ`;

                if (ee.lowest_price) {
                  star = star + ``;
                }
                
                if (ee.remarks) {
                  star = star + `,*${ee.remarks}`;
                }
                star = star + "\n";
              } else {
                if((ee.price==0.01)&&ee.measure=='SET'){
                   star =star +
                  `-- ${ee.name_zh || ee.name_en || "-"}：`

                }else if((ee.price==0.01)&&ee.measure!='SET'){
                  star =star +
                  `-- ${ee.name_zh || ee.name_en || "-"}：${ee.code}/${ee.measure} ,`;
                }else{
                  star =star +
                  `-- ${ee.name_zh || ee.name_en || "-"}：${(ee.price==0.01)?0:Number(
                    ee.price
                  ).toFixed(2)} ${ee.code}/${ee.measure} ,`;
                }

               
                if (ee.lowest_price) {
                  star = star + `MINI:${ee.lowest_price}`;
                }
                if(ee.lowest_price && ee.remarks){
                  star = star + `,`
                }
                if (ee.remarks) {
                  star = star + `*${ee.remarks}`;
                }
                star = star + "\n";
              }
            });

            if(res.plan_remarks_list.length){
              star = star + "备注：";
              res.plan_remarks_list.forEach(element => {
                  star = star+element+'\n'
              });
            }


          }
        });
      }

      let lastMsg = ""
      if(this.check_tips){
        lastMsg = "\n" + "订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。\n可自主登录运抵达（www.yundida.com）查询危拼运价。";
      }
        
      var content = port.concat(star, lastMsg);

      // 使用textarea支持换行，使用input不支持换行
      const textarea = document.createElement("textarea");
      textarea.value = content;
      document.body.appendChild(textarea);

      textarea.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$message({
          message: "您选择的费用信息已经复制到剪贴板",
          type: "success",
        });
        this.dialogVisible = false;
      }
      document.body.removeChild(textarea);
    },

    async remoteUnno(e) {
      this.unnoLoading = true;
      let data = await this.$store.dispatch("API_bookingSpace/getUnno", {
        un_no: e,
      });
      this.unnoOptions = data.data;
    },
    async getUnno() {
      let data = await this.$store.dispatch("API_bookingSpace/getUnno", {
        un_no: this.unno,
      });
      if (data.data.length) {
        this.unnoMsg = data.data[0];
      } else {
        this.unno = this.$route.query.unno;
      }
    },
    handleClose() {
      this.dialogVisible=false
    },
    async getHeader(normal=false) {
      this.loading = true;
      if (this.unno) {
        this.getUnno();
      }
      let params = {
        freight_id: this.$route.query.id,
        user_type: this.USER_INFO ? this.USER_INFO.user_type : 1,
        unno: this.unno,
        dangerous_status: this.unno ? 1 : 0,
      };

      let result = await Promise.all([
        this.$store.dispatch("API_freight/getFclInfoDetail", params),
      ]);
      this.loading = false;
      if (result[0].success) {
        let data = result[0].data;
        data.week = [];
        data.abs = Math.abs(data.price_lcl_dangerous);
        data.shipping_date_arr = data.shipping_date.split(",");

        data.price_20gp=data.price_20gp.toFixed()
        data.price_40gp=data.price_40gp.toFixed()
        data.price_40hc=data.price_40hc.toFixed()
        data.price_45gp=data.price_45gp.toFixed()

        data.shipping_date_arr.forEach((ee) => {
          if (ee == 1) {
            data.week.push("周一");
          }
          if (ee == 2) {
            data.week.push("周二");
          }
          if (ee == 3) {
            data.week.push("周三");
          }
          if (ee == 4) {
            data.week.push("周四");
          }
          if (ee == 5) {
            data.week.push("周五");
          }
          if (ee == 6) {
            data.week.push("周六");
          }
          if (ee == 7) {
            data.week.push("周日");
          }
        });
        // 后台用身份去计算价格
        this.options[0].price = data.price_20gp;
        this.options[0].reject = data.reject_20gp;
        this.options[0].sell = data.price_20gp;
        // this.options[0].sell = data.price_20gp_of_sell.toFixed();
        this.options[1].price = data.price_40gp;
        this.options[1].reject = data.reject_40gp;
        this.options[1].sell = data.price_40gp;
        // this.options[1].sell = data.price_40gp_of_sell.toFixed();
        this.options[2].price = data.price_40hc;
        this.options[2].reject = data.reject_40hc;
        this.options[2].sell = data.price_40hc;
        // this.options[2].sell = data.price_40hc_of_sell.toFixed();
        // this.options[3].price = data.price_45gp;
        // this.options[3].sell = data.price_45gp_of_sell;
        if(normal){
          this.options.forEach((ee,index)=>{
            if(!this.checkedArr.length && !ee.reject){

              this.checkedArr.push(ee.name)
              this.options[index].checked = true
              this.seaTotal =  this.options[index].price

            }
          })
        }
        this.volume = data.mini_cargo;
        this.header = data;
        this.componentSea();
      }

      let eq_types=this.options.find(item=>{
        return item.checked
      })
      let params2 = {
        cargo_type: this.unno ? 1 : 2,
        spell_type: 1,
        shipping_line: this.$route.query.shipping_line_id,
        freight_id: this.$route.query.id,
        // volume: this.volume,
        // weight: this.weight,
        // piece_num: this.piece_num,

      };
      if(this.first_load){
        params2.eq_types=eq_types.name
      }else{
        params2.eq_types=this.options
          .filter((res) => {
            return res.checked;
          })
          .map((res) => {
            return res.name;
          })
          .join(",")
      }
      this.first_load=false
      let cost_money=await this.$store.dispatch("API_freight/getnewFreightInfo", params2)

      if (cost_money.success) {
        cost_money.data.forEach((ee, index) => {
          ee.canCopy = true;
          ee.open = true;
          if (index > 0) {
            ee.open = false;
          }
        });
        this.costList = cost_money.data;
      }
    },

    async recalculate() {
      let res = await this.$store.dispatch("API_freight/check_unno", {
        unno: this.unno,
      });
      if (res.success) {
        this.getHeader();
      } else {
        this.$confirm("您输入的UN NO有误，请重新输入", "提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {})
          .catch(() => {});
      }
    },
    componentSea() {
      let num = 0;
      this.options.forEach((res) => {
        if (res.checked) {
          num = num + res.price*1;
        }
      });
      this.seaTotal = num.toFixed();
    },
    handleLogin() {
      this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
    },
    handleBookingSpace() {
      let info = {
        from_port_name_en: this.header.from_port_name_en,
        from_port_name_zh: this.header.from_port_name_zh,
        from_port: this.header.from_port,
        dest_port_name_en: this.header.dest_port_name_en,
        dest_port_name_zh: this.header.dest_port_name_zh,
        dest_port: this.header.dest_port,
      };
      this.$router.push({
        path: "/bookingSpace",
        query: {
          info,
          activeName: "first",
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.page_tips{
  padding:26px 0;
  display: flex;
  align-items: center;
  i{
    color: #FAAD14;
    font-size:30px;
    padding-right:9px;
  }
  span{
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
}
.itemTips {
  font-size: 16px;
  color: #ff7727;
  padding-top: 20px;
}
.lastTips {
  font-size: 14px;
  color: #000;
  font-weight: 900;
  display: flex;
  align-items: center;
  i {
    color: #ff7727;
    font-size: 25px;
    margin-right: 10px;
  }
}
.unnoMsgBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 600px;
  .psn {
    width: 300px;
  }
  .class {
    width: 100px;
  }
  .PG {
    width: 100px;
  }
}
.unno {
  display: flex;
  align-items: center;
}
.mohu {
  color: transparent;
  -moz-user-select: none; //火狐
  -webkit-user-select: none; //webkit浏览器（Chrome，Opera，Safari等）
  -ms-user-select: none; //IE10
  -khtml-user-select: none; //早期浏览器
  user-select: none;
  text-shadow: #000 0 0 8px;
}
.moneyList {
  background: #fff;
  padding: 20px;
  .seaItem {
    margin-bottom: 30px;
    .itemTitleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        font-size: 16px;
        color: #999;
        cursor: pointer;
      }
    }
    .itemTitleBox {
      padding-bottom: 20px;
    }
    .el-icon-ship {
      display: inline-block;
      width: 25px;
      height: 25px;
      background: #ff7727;
      border-radius: 50%;
      text-align: center;
      line-height: 25px;
      font-size: 16px;
      color: #fff;
      margin-right: 10px;
    }
    .itemTitle {
      font-weight: 900;
      font-size: 18px;
    }
    .itemMOney {
      font-weight: 900;
      font-size: 18px;
    }

    .tableLine {
      display: flex;
      align-items: center;
      padding: 13px 0;
    }
    .tableLine {
      .table1 {
        width: 250px;
      }
      .table2 {
        width: 220px;
      }
      .table3 {
        width: 220px;
      }
      .table4 {
        width: 220px;
      }
      .table5 {
        width: 220px;
      }
      .table6 {
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
    }
    .tableTitle {
      background: #fbfcfa;
      border-bottom: 1px solid #ebeef5;
    }
  }
}
.seaMoney {
  background: #fff;
  padding: 20px;
  margin-bottom: 10px;
  .seaTitle {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 900;
  }
  .searchBox {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .flex {
      display: flex;
      align-items: center;
    }
  }
}
.chooseItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .itemtitle {
    font-size: 14px;
  }

  .switch {
    height: 50px;
  }
}
.booking {
  background-color: #91be42;
  border-color: #91be42;
}
.content_area {
  background: #f3f6f8;
  min-height: 83vh;
  width: 1600px;
  .mainbox{
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
.title {
  display: flex;
  height: 58px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  .titleText {
    font-weight: 800;
    color: #1f292e;
    font-size: 16px;
    padding-left: 10px;
  }
  .booking {
    background-color: #91be42;
    border-color: #91be42;
  }
}
.content {
  background: #f3f6f8;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 20px;
  justify-content: space-between;
  //min-height: 500px;
  .item {
    width: 49%;
    background: #ffffff;
    margin-top: 16px;
    .costTitle {
      font-weight: 800;
      color: #1f292e;
      font-size: 16px;
      padding: 20px;
    }
    .line {
      height: 1px;
      width: 100%;
      background: #dcdfe6;
    }
    .costContent {
      padding: 20px;
      .costItem {
        display: flex;
        justify-content: space-between;
        .costItemTitle {
          color: #1f292e;
        }
        .costItemContent {
          color: #f7a350;
          font-size: 18px;
          font-weight: 800;
          span {
            font-size: 14px;
            color: #1f292e;
            font-weight: 400;
            margin-left: 4px;
          }
        }
        .itRemarks {
          margin-top: 6px;
          color: #999999;
        }
        .code {
          font-size: 12px;
          color: #999999;
        }
      }
      .constItem2 {
        margin-top: 20px;
        color: #f46401;
      }
    }
  }
}
.goLogin {
  text-align: center;
  color: #298df8;
  width: 100%;
  padding: 20px 0;
}
.item2Text {
  padding-left: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  color: #333333;
}
.pageHint {
  margin-top: 10px;
  margin-left: 15px;
  padding: 0px 0 15px 15px;
  color: red;
}

::v-deep .el-checkbox {
  .el-checkbox__label {
    color: #91be42;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #91be42;
    border-color: #91be42;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner,
  .el-checkbox__inner:hover {
    border-color: #91be42;
  }
}
</style>
